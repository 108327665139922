<template>
  <div>
    <a-card>
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <a-button class="btn btn-success ml-2 mt-2 mb-2" @click="AskForce"
            >Initial All Form</a-button
          ><br />
          <div class="ml-3">
            Form ini akan secara otomatis memproses inisialisasi form-form
            berikut:<br />
            <!-- Initial:<br /> -->
            <!-- - Form GB-04<br />
          - Form GB-06 (tapi ini 3 bulan sekali, karena triwulanan)<br />
          - Form GB-07<br />
          - Form GB-08<br />
          - Form GB-09<br />
          <br />
          Ambil periode sebelumnya:<br />
          - Form GB-01.1<br />
          - Form GB-01.2<br />
          - Form GB-01.3<br />
          - Form GB-01.4<br />
          - Form GB-01.5<br />
          - Form KC-01<br /> -->
          </div>
          <a-table
            :key="componentKeyI"
            class="mt-2"
            :columns="columnViewTalbeI"
            :dataSource="dataViewTableI"
            :pagination="false"
            size="small"
          >
            <span
              slot="status"
              slot-scope="text, record"
              :class="record.statuscondition"
            >
              {{ text }} <span v-if="text !== '' && text !== null">
              <a-icon
                type="close"
                :class="record.statuscondition"
                v-if="record.statuscondition === 'text-danger'"
              />
              <a-icon type="check" :class="record.statuscondition" v-else />
              </span>
            </span>
          </a-table>
          <a-table
            :key="componentKeyC"
            class="mt-2"
            :columns="columnViewTalbeC"
            :dataSource="dataViewTableC"
            :pagination="false"
            size="small"
          >
            <span
              slot="status"
              slot-scope="text, record"
              :class="record.statuscondition"
            >
              {{ text }} <span v-if="text !== '' && text !== null">
              <a-icon
                type="close"
                :class="record.statuscondition"
                v-if="record.statuscondition === 'text-danger'"
              />
              <a-icon type="check" :class="record.statuscondition" v-else />
              </span>
            </span>
          </a-table>
        </div>
      </div>
    </a-card>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import moment from 'moment'
// import { Modal } from 'ant-design-vue'

export default {
  data() {
    return {
      columnViewTalbeI: [
        {
          title: 'Initial Forms',
          dataIndex: 'formname',
          width: '30%',
        },
        {
          title: 'Status',
          dataIndex: 'status',
          width: '30%',
          scopedSlots: { customRender: 'status' },
        },
      ],
      columnViewTalbeC: [
        {
          title: 'Forms Ambil Periode Sebelumnya',
          dataIndex: 'formname',
          width: '30%',
        },
        {
          title: 'Status',
          dataIndex: 'status',
          width: '30%',
          scopedSlots: { customRender: 'status' },
        },
      ],
      dataViewTableI: [
        {
          formname: 'Form GB-04',
          actualform: 'gb_form0400',
          statuscondition: '',
          status: '',
          response: null,
        },
        {
          formname: 'Form GB-06',
          actualform: 'gb_form0600',
          statuscondition: '',
          status: '',
          response: null,
        },
        {
          formname: 'Form GB-07',
          actualform: 'gb_form0700',
          statuscondition: '',
          status: '',
          response: null,
        },
        {
          formname: 'Form GB-08',
          actualform: 'gb_form0800',
          statuscondition: '',
          status: '',
          response: null,
        },
        {
          formname: 'Form GB-09',
          actualform: 'gb_form0900',
          statuscondition: '',
          status: '',
          response: null,
        },
      ],
      dataViewTableC: [
        {
          formname: 'Form GB-01.1',
          actualform: 'gb_form0101',
          statuscondition: '',
          status: '',
          response: null,
        },
        {
          formname: 'Form GB-01.2',
          actualform: 'gb_form0102',
          statuscondition: '',
          status: '',
          response: null,
        },
        {
          formname: 'Form GB-01.3',
          actualform: 'gb_form0103',
          statuscondition: '',
          status: '',
          response: null,
        },
        {
          formname: 'Form GB-01.4',
          actualform: 'gb_form0104',
          statuscondition: '',
          status: '',
          response: null,
        },
        {
          formname: 'Form GB-01.5',
          actualform: 'gb_form0105',
          statuscondition: '',
          status: '',
          response: null,
        },
        {
          formname: 'Form KC-01',
          actualform: 'kc_form0100',
          statuscondition: '',
          status: '',
          response: null,
        },
      ],
      resultResponse: [],
      form: [
        'gb_form0400',
        'gb_form0600',
        'gb_form0700',
        'gb_form0800',
        'gb_form0900',
      ],
      formPrevious: [
        'gb_form0101',
        'gb_form0102',
        'gb_form0103',
        'gb_form0104',
        'gb_form0105',
        'kc_form0100',
      ],
      componentKeyI: 0,
      componentKeyC: 1,
    }
  },
  methods: {
    moment,
    AskForce() {
      var exception = ['03', '06', '09', '12']
      var today = lou.datenow().substring(5, 7)
      if (exception.includes(today)) {
        // console.log('included', today)
        var target = this.form.findIndex((x) => x === 'gb_form0600')
        if (target < 0) {
          this.form.push('gb_form0600')
        }
      } else {
        // console.log('not included', today)
        this.form.splice(1, 1)
      }
      this.AskAgain()
      // Modal.confirm({
      //   title: 'GB Form 06?',
      //   content: 'Apakah anda ingin mensertakan GB form 06!',
      //   okText: 'Yes',
      //   okType: 'success',
      //   cancelText: 'No',
      //   onOk: () => {
      //     var target = this.form.findIndex((x) => x === 'gb_form0600')
      //     if (target < 0) {
      //       this.form.push('gb_form0600')
      //     }
      //     this.AskAgain()
      //   },
      //   onCancel: () => {
      //     this.form.splice(1, 1)
      //   },
      // })
    },
    AskAgain() {
      // Modal.confirm({
      //   title: 'Timpa?',
      //   content: 'Apakah anda ingin menimpa data saat ini!',
      //   okText: 'Yes',
      //   okType: 'success',
      //   cancelText: 'No',
      //   onOk: () => {
      this.InitAllForm(true)
      //   },
      //   onCancel: () => {
      //     this.InitAllForm(false)
      //   },
      // })
    },
    async InitAllForm(force) {
      var rescopypreviousform4 = await lou.customUrlGet(
        'kc_form0400/copyprevious?force=' + force,
        false,
        false,
      )
      if (rescopypreviousform4) {
      }
      var res = []
      this.resultResponse = []
      for (let i = 0; i < this.form.length; i++) {
        const element = this.form[i]
        var tres = await lou.customUrlGet(
          element + '/initial?force=' + force,
          false,
          false,
          true,
        )
        this.resultResponse.push({
          form: element,
          res: tres,
        })
        var targetI = this.dataViewTableI.findIndex(
          (x) => element === x.actualform,
        )
        if (targetI >= 0) {
          if (tres.isError) {
            this.dataViewTableI[targetI].statuscondition = 'text-danger'
            this.dataViewTableI[targetI].status = tres.message
          } else {
            this.dataViewTableI[targetI].statuscondition = 'text-primary'
            this.dataViewTableI[targetI].status = tres.message
          }
        } else {
          this.dataViewTableC[i].statuscondition = 'text-danger'
          this.dataViewTableC[i].status = 'Tidak ada response dari server.'
        }
        // this.componentKeyI++
        if (tres) {
          res.push({
            form: element,
            res: tres,
          })
        }
        if (i === this.form.length - 1) {
          if (res.length === this.form.length) {
            lou.shownotif(
              'Success',
              'All form has been successfully inititalized.',
              'success',
            )
          } else {
            lou.shownotif(
              'Info',
              "There's form that failed to be initialized.",
              'info',
            )
          }
        }
      }
      this.CopyPreviousAllForm(force)
    },
    async CopyPreviousAllForm(force) {
      var res = []
      for (let i = 0; i < this.formPrevious.length; i++) {
        const element = this.formPrevious[i]
        var tres = await lou.customUrlGet(
          element + '/copyprevious?force=' + force,
          false,
          false,
          true,
        )
        this.resultResponse.push({
          form: element,
          res: tres,
        })
        var targetC = this.dataViewTableC.findIndex(
          (x) => element === x.actualform,
        )
        if (targetC >= 0) {
          if (tres.isError) {
            this.dataViewTableC[targetC].statuscondition = 'text-danger'
            this.dataViewTableC[targetC].status = tres.message
          } else {
            this.dataViewTableC[targetC].statuscondition = 'text-primary'
            this.dataViewTableC[targetC].status = tres.message
          }
        } else {
          this.dataViewTableC[i].statuscondition = 'text-danger'
          this.dataViewTableC[i].status = 'Tidak ada response dari server.'
        }
        // this.componentKeyC++
        if (tres) {
          res.push({
            form: element,
            res: tres,
          })
        }
        if (i === this.formPrevious.length - 1) {
          if (res.length === this.formPrevious.length) {
            lou.shownotif(
              'Success',
              'All form has been successfully executed.',
              'success',
            )
          } else {
            lou.shownotif(
              'Info',
              "There's form that failed to be executed.",
              'info',
            )
          }
        }
      }
      // this.checkStatus()
    },
    checkStatus() {
      // this.dataViewTableI.forEach((element, index) => {
      // })
      for (let i = 0; i < this.dataViewTableI.length; i++) {
        const element = this.dataViewTableI[i]

        var targetI = this.resultResponse.findIndex(
          (x) => element.actualform === x.form,
        )
        if (targetI >= 0) {
          var checkresponseI = this.resultResponse[targetI].res.isError
          if (checkresponseI) {
            this.dataViewTableI[i].statuscondition = 'text-danger'
            this.dataViewTableI[i].status = this.resultResponse[
              targetI
            ].res.message
          } else {
            this.dataViewTableI[i].statuscondition = 'text-primary'
            this.dataViewTableI[i].status = this.resultResponse[
              targetI
            ].res.message
          }
        }
      }
      for (let i = 0; i < this.dataViewTableC.length; i++) {
        const element = this.dataViewTableC[i]

        var targetC = this.resultResponse.findIndex(
          (x) => element.actualform === x.form,
        )
        if (targetC >= 0) {
          var checkresponseC = this.resultResponse[targetC].res.isError
          if (checkresponseC) {
            this.dataViewTableC[i].statuscondition = 'text-danger'
            this.dataViewTableC[i].status = this.resultResponse[
              targetC
            ].res.message
          } else {
            this.dataViewTableC[i].statuscondition = 'text-primary'
            this.dataViewTableC[i].status = this.resultResponse[
              targetC
            ].res.message
          }
        } else {
          this.dataViewTableC[i].statuscondition = 'text-danger'
          this.dataViewTableC[i].status = 'Tidak ada response dari server.'
        }
      }
      this.componentKey += 1
    },
  },
}
</script>

<style lang="scss" scoped></style>
